export const booksRead = [
  {
    year: 'Currently reading',
    books: [
      // {
      //   title: '',
      //   author: '',
      // },
      {
        title: 'Tomorrow, Tomorrow, and Tomorrow',
        author: 'Gabrielle Zevin',
      },
    ],
  },
  {
    year: '2024',
    books: [
      {
        title:
          "The Manager's Path: A Guide for Tech Leaders Navigating Growth & Change",
        author: 'Camille Fournier',
      },
      {
        title: 'Hidden Potential: The Science of Achieving Greater Things',
        author: 'Adam Grant',
      },
      {
        title: 'The Curious Secrets of Yesterday',
        author: 'Namrata Patel',
        dateRead: 'August 2024',
      },
      {
        title: 'Harry Potter and the Half-Blood Prince',
        author: 'J.K. Rowling',
        dateRead: 'May 2024',
      },
      {
        title:
          'Ultralearning: Master Hard Skills, Outsmart the Competition, and Accelerate Your Career',
        author: 'Scott H. Young',
        dateRead: 'May 2024',
        reread: true,
      },
      {
        title: 'The Dictionary of Lost Words',
        author: 'Pip Williams',
        dateRead: 'May 2024',
      },
      {
        title: 'Nasty, Brutish, and Short: Adventures in Philosophy with Kids',
        author: 'Scott Hershovitz',
        dateRead: 'April 2024',
      },
      {
        title: 'Napalm & Silly Putty',
        author: 'George Carlin',
        dateRead: 'April 2024',
      },
      {
        title: 'Kitchen Confidential: Adventures in the Culinary Underbelly',
        author: 'Anthony Bourdain',
        dateRead: 'March 2024',
      },
      {
        title:
          'Supercommunicators: How to Unlock the Secret Language of Connection',
        author: 'Charles Duhigg',
        dateRead: 'March 2024',
      },
      {
        title: "I'm Glad My Mom Died",
        author: 'Jennette McCurdy',
        dateRead: 'February 2024',
      },
      {
        title:
          'Never Split the Difference: Negotiating As If Your Life Depended On It',
        author: 'Chris Voss',
        dateRead: 'February 2024',
        reread: true,
      },
      {
        title: 'Why Marriages Succeed or Fail: And How You Can Make Yours Last',
        author: 'John Gottman',
        dateRead: 'February 2024',
      },
      {
        title: 'Designing Your Work Life',
        author: 'Bill Burnett, Dave Evans',
        dateRead: 'February 2024',
      },
      {
        title:
          'Attached: The New Science of Adult Attachment and How It Can Help You Find –and Keep- Love',
        author: 'Amir Levine, Rachel S.F. Heller',
        dateRead: 'January 2024',
        reread: true,
      },
    ],
  },
  {
    year: '2023',
    books: [
      {
        title:
          'The Culture Map: Breaking Through the Invisible Boundaries of Global Business',
        author: 'Erin Meyer',
        dateRead: 'December 2023',
        reread: true,
      },
      {
        title: 'Catching Fire',
        author: 'Suzanne Collins',
        dateRead: 'November 2023',
      },
      {
        title: 'Educated',
        author: 'Tara Weston',
        dateRead: 'October 2023',
        // audiobook: true,
      },
      {
        title: 'Harry Potter and the Order of the Phoenix',
        author: 'J.K. Rowling',
        dateRead: 'October 2023',
      },
      {
        title:
          'Atlas of the Heart: Mapping Meaningful Connection and the Language of Human Experience',
        author: 'Brené Brown',
        dateRead: 'October 2023',
        // audiobook: true,
      },
      {
        title:
          "The Science of Sleep: Stop Chasing a Good Night's Sleep and Let It Find You",
        author: 'Heather Darwall-Smith',
        dateRead: 'October 2023',
        // audiobook: true,
      },
      {
        title:
          'Coping Skills: Tools & Techniques for Every Stressful Situation',
        author: 'Faith G. Harper',
        dateRead: 'October 2023',
        // audiobook: true,
      },
      {
        title: 'Dune Messiah',
        author: 'Frank Herbert',
        dateRead: 'September 2023',
        // audiobook: true,
      },
      {
        title: 'Dune',
        author: 'Frank Herbert',
        dateRead: 'September 2023',
        // audiobook: true,
      },
      {
        title:
          'Demystifying Disability: What to Know, What to Say, and How to Be an Ally',
        author: 'Emily Ladau',
        dateRead: 'September 2023',
        // audiobook: true,
      },
      {
        title: 'The Future We Choose: Surviving the Climate Crisis',
        author: 'Christiana Figueres and Tom Rivett-Carnac',
        dateRead: 'September 2023',
        // audiobook: true,
      },
      {
        title:
          'The Conscious Closet: The Revolutionary Guide to Looking Good While Doing Good',
        author: 'Elizabeth L. Cline',
        dateRead: 'August 2023',
        // audiobook: true,
      },
      {
        title: 'Where the Deer and the Antelope Play',
        author: 'Nick Offerman',
        dateRead: 'August 2023',
        // audiobook: true,
      },
      {
        title: 'Harry Potter and the Goblet of Fire',
        author: 'J.K. Rowling',
        dateRead: 'July 2023',
      },
      {
        title: 'Indelible City: Dispossession and Defiance in Hong Kong',
        author: 'Louisa Lim',
        dateRead: 'July 2023',
        // audiobook: true,
      },
      {
        title: 'Mismatch: How Inclusion Shapes Design',
        author: 'Kat Holmes',
        dateRead: 'July 2023',
        // audiobook: true,
      },
      {
        title: 'How to Be Perfect: The Correct Answer to Every Moral Question',
        author: 'Michael Schur',
        dateRead: 'June 2023',
      },
      {
        title: 'Finding Flow: The Psychology of Engagement with Everyday Life',
        author: 'Mihaly Csikszentmihalyi',
        dateRead: 'June 2023',
        // audiobook: true,
      },
      {
        title:
          'Atomic Habits: An Easy & Proven Way to Build Good Habits & Break Bad Ones',
        author: 'James Clear',
        dateRead: 'June 2023',
        // audiobook: true,
      },
      {
        title: 'Babel: Or the Necessity of Violence: An Arcane History',
        author: 'R.F. Kuang',
        dateRead: 'June 2023',
      },
      {
        title: 'I Am C-3PO: The Inside Story',
        author: 'Anthony Daniels',
        dateRead: 'June 2023',
        // audiobook: true,
      },
      {
        title: 'The Gifts of Imperfection',
        author: 'Brené Brown',
        dateRead: 'June 2023',
      },
      {
        title: 'All Things Aside: Absolutely Correct Opinions',
        author: 'Iliza Shlesinger',
        dateRead: 'May 2023',
        // audiobook: true,
      },
      {
        title: 'Crying in H Mart',
        author: 'Michelle Zauner',
        dateRead: 'May 2023',
      },
      {
        title:
          "Quiet: The Power of Introverts in a World That Can't Stop Talking",
        author: 'Susan Cain',
        dateRead: 'May 2023',
      },
      {
        title: 'Notes from a Young Black Chef',
        author: 'Kwame Onwuachi',
        dateRead: 'April 2023',
      },
      {
        title: 'Originals: How Non-Conformists Move the World',
        author: 'Adam Grant',
        dateRead: 'March 2023',
      },
      {
        title: 'Effortless: Make It Easier to Do What Matters Most',
        author: 'Greg McKeown',
        dateRead: 'February 2023',
      },
      {
        title: 'Peak: Secrets from the New Science of Expertise',
        author: 'K. Anders Ericsson, Robert Pool',
        dateRead: 'February 2023',
      },
      {
        title:
          'Ultralearning: Master Hard Skills, Outsmart the Competition, and Accelerate Your Career',
        author: 'Scott H. Young',
        dateRead: 'January 2023',
      },
      {
        title:
          'Horizontal Parenting: How to Entertain Your Kid While Lying Down',
        author: 'Michelle Woo',
        dateRead: 'January 2023',
      },
      {
        title: 'The Power of When',
        author: 'Michael Breus',
        dateRead: 'January 2023',
      },
    ],
  },
  {
    year: '2022',
    books: [
      {
        title: 'The Life-Changing Magic of Tidying Up',
        author: 'Marie Kondō',
        dateRead: 'December 2022',
      },
      {
        title: 'Relationships: Learning to Love',
        author: 'The School of Life',
        dateRead: 'December 2022',
      },
      {
        title: 'The Attachment Theory Workbook',
        author: 'Annie Chen LMFT',
        dateRead: 'December 2022',
      },
      {
        title:
          'Attached: The New Science of Adult Attachment and How It Can Help You Find –and Keep- Love',
        author: 'Amir Levine, Rachel S.F. Heller',
        dateRead: 'December 2022',
      },
      {
        title: 'The Joy of Not Thinking: A Radical Approach to Happiness',
        author: 'Tim Grimes',
        dateRead: 'November 2022',
      },
      {
        title: 'Maybe You Should Talk to Someone',
        author: 'Lori Gottlieb',
        dateRead: 'November 2022',
      },
      {
        title: "Why Didn't They Teach Me This in School?",
        author: 'Cary Siegel',
        dateRead: 'November 2022',
      },
      {
        title: 'I Will Teach You To Be Rich',
        author: 'Ramit Sethi',
        dateRead: 'November 2022',
      },
      {
        title: 'Twilight',
        author: 'Stephanie Meyer',
        dateRead: 'November 2022',
      },
      {
        title: 'Speed Reading: Learn to Read a 200+ Page Book in 1 Hour',
        author: 'Kam Knight',
        dateRead: 'November 2022',
      },
      {
        title:
          "Good to Great: Why Some Companies Make the Leap, and Others Don't",
        author: 'James C. Collins',
        dateRead: 'October 2022',
      },
      {
        title: 'Think and Grow Rich',
        author: 'Napoleon Hill',
        dateRead: 'October 2022',
      },
      {
        title: "13 Things Mentally Strong People Don't Do",
        author: 'Amy Morin',
        dateRead: 'October 2022',
      },
      {
        title: 'Radical Candor: Fully Revised & Updated Edition',
        author: 'Kim Scott',
        dateRead: 'September 2022',
      },
      {
        title: 'The Hunger Games',
        author: 'Suzanne Collins',
        dateRead: 'September 2022',
      },
      {
        title: 'That Thing Rich People Do: Required Reading for Investors',
        author: 'Kaye A. Thomas',
        dateRead: 'August 2022',
      },
      {
        title: 'The Little Book of Hygge: The Danish Way to Live Well',
        author: 'Meik Wiking',
        dateRead: 'August 2022',
      },
      {
        title: 'The Dumb Things Smart People Do With Their Money',
        author: 'Jill Schlesinger',
        dateRead: 'August 2022',
      },
      {
        title: 'The Psychology of Money',
        author: 'Morgan Housel',
        dateRead: 'June 2022',
      },
      {
        title: 'Every Layout',
        author: 'Heydon Pickering',
        dateRead: 'June 2022',
      },
      {
        title: 'De-Coding the Technical Interview Process',
        author: 'Emma Bostian',
        dateRead: 'May 2022',
      },
      {
        title: 'Belonging at Work',
        author: 'Rhodes Perry',
        dateRead: 'May 2022',
        // audiobook: true,
      },
      {
        title: 'The Dip: A Little Book That Teaches You When to Quit',
        author: 'Seth Godin',
        dateRead: 'April 2022',
      },
      {
        title:
          'Start With Why: How Great Leaders Inspire Everyone to Take Action',
        author: 'Simon Sinek',
        dateRead: 'April 2022',
        // audiobook: true,
      },
      {
        title: 'The 7 Habits of Highly Effective People',
        author: 'Stephen Covey',
        dateRead: 'April 2022',
      },
      {
        title:
          'The Happiness Advantage: The Seven Principles of Positive Psychology',
        author: 'Shawn Achor',
        dateRead: 'March 2022',
      },
      {
        title: 'The Princess Diarist',
        author: 'Carrie Fisher',
        dateRead: 'March 2022',
      },
      {
        title: 'Daring Greatly',
        author: 'Brené Brown',
        dateRead: 'February 2022',
        // audiobook: true,
      },
      {
        title:
          'Failing Up: How to Take Risks, Aim Higher, and Never Stop Learning',
        author: 'Leslie Odom, Jr.',
        dateRead: 'February 2022',
      },
      {
        title:
          'Never Split the Difference: Negotiating As If Your Life Depended On It',
        author: 'Chris Voss',
        dateRead: 'January 2022',
      },
      {
        title: 'Klara and the Sun',
        author: 'Kazuo Ishiguro',
        dateRead: 'January 2022',
      },
      {
        title: 'How to Win Friends & Influence People',
        author: 'Dale Carnegie',
        dateRead: 'January 2022',
        // audiobook: true,
      },
      {
        title: 'The Wind-Up Bird Chronicle',
        author: 'Haruki Murakami',
        dateRead: 'January 2022',
      },
    ],
  },
  {
    year: '2021',
    books: [
      {
        title:
          'The Daily Stoic: 366 Meditations on Wisdom, Perseverance, and the Art of Living',
        author: 'Ryan Holiday, Stephen Hanselman',
        dateRead: 'December 2021',
      },
      {
        title: 'Foundation',
        author: 'Isaac Asimov',
        dateRead: 'December 2021',
      },
      {
        title: 'Infidelity: Why Men and Women Cheat',
        author: 'Kenneth Paul Rosenberg',
        dateRead: 'November 2021',
      },
      {
        title: 'How to Live: 27 conflicting answers and one weird conclusion',
        author: 'Derek Sivers',
        dateRead: 'November 2021',
      },
      {
        title: 'Your Music and People: Creative and Considerate Fame',
        author: 'Derek Sivers',
        dateRead: 'October 2021',
      },
      {
        title: "Don't Sweat the Small Stuff, and It's All Small Stuff",
        author: 'Richard Carlson',
        dateRead: 'October 2021',
      },
      {
        title: 'Invisible Women: Data Bias in a World Designed for Men',
        author: 'Caroline Criado Perez',
        dateRead: 'September 2021',
        // audiobook: true,
      },
      {
        title: 'Designing the Mind: The Principles of Psychitecture',
        author: 'Designing the Mind, Ryan A. Bush',
        dateRead: 'August 2021',
      },
      {
        title:
          'Creativity Inc.: Overcoming the Unseen Forces That Stand in the Way of True Inspiration',
        author: 'Ed Catmull, Amy Wallace',
        dateRead: 'August 2021',
      },
      {
        title: 'Opening Up',
        author: 'Tristan Taormino',
        dateRead: 'June 2021',
      },
      {
        title: 'Plato and a Platypus Walk into a Bar',
        author: 'Thomas Cathcart, Daniel Klein',
        dateRead: 'May 2021',
      },
      {
        title:
          'What Does It All Mean?: A Very Short Introduction to Philosophy',
        author: 'Thomas Nagel',
        dateRead: 'May 2021',
      },
      {
        title: "Hell Yeah or No: What's Worth Doing",
        author: 'Derek Sivers',
        dateRead: 'April 2021',
      },
      {
        title: 'The Headspace Guide to Meditation & Mindfulness',
        author: 'Andy Puddicombe',
        dateRead: 'April 2021',
      },
      {
        title: 'A Little History of the World',
        author: 'E.H. Gombrich',
        dateRead: 'March 2021',
      },
      {
        title: 'Introducing Mathematics: A Graphic Guide',
        author: 'Ziauddin Sardar, Jerry Ravetz, Borin Van Loon',
        dateRead: 'January 2021',
      },
      {
        title: 'As a Man Thinketh',
        author: 'James Allen',
        dateRead: 'January 2021',
      },
      {
        title: 'Essentialism: The Disciplined Pursuit of Less',
        author: 'Greg McKeown',
        dateRead: 'January 2021',
      },
    ],
  },
  {
    year: '2020',
    books: [
      {
        title: 'How to Think Like a Roman Emperor',
        author: 'Donald Robertson',
        dateRead: 'December 2020',
      },
      {
        title: 'The Power of Habit',
        author: 'Charles Duhigg',
        dateRead: 'December 2020',
      },
      {
        title: 'A Walk in the Woods',
        author: 'Dr. Joseph Parent, Nancy Parent',
        dateRead: 'September 2020',
      },
      {
        title:
          'Declutter Your Mind: How to Stop Worrying, Relieve Anxiety, and Eliminate Negative Thinking',
        author: 'S.J. Scott, Barrie Davenport',
        dateRead: 'September 2020',
      },
      {
        title:
          "Dreyer's English: An Utterly Correct Guide to Clarity and Style",
        author: 'Benjamin Dreyer',
        dateRead: 'September 2020',
      },
      {
        title: 'A History of U.S. Feminisms',
        author: 'Rory Dicker',
        dateRead: 'August 2020',
      },
      {
        title:
          'The Ride of a Lifetime: Lessons Learned from 15 Years as CEO of the Walt Disney Company',
        author: 'Robert Iger',
        dateRead: 'August 2020',
      },
      {
        title: 'Cold Hard Truth on Men, Women, & Money',
        author: "Kevin O'Leary",
        dateRead: 'July 2020',
      },
      {
        title: 'Rich Dad, Poor Dad',
        author: 'Robert T. Kiyosaki',
        dateRead: 'July 2020',
      },
      {
        title: 'Tax-Free Wealth',
        author: 'Tom Wheelwright',
        dateRead: 'July 2020',
      },
      {
        title: '15 Invaluable Laws of Growth',
        author: 'John C. Maxwell',
        dateRead: 'July 2020',
      },
      {
        title:
          '$100 Startup; Reinvent the Way You Make a Living, Do What You Love, and Create a New Future',
        author: 'Chris Guillebeau',
        dateRead: 'July 2020',
      },
      {
        title: 'Anything You Want',
        author: 'Derek Sivers',
        dateRead: 'July 2020',
      },
      {
        title: 'Inspired: How to Create Tech Products Customers Love',
        author: 'Marty Cagan',
        dateRead: 'July 2020',
      },
      {
        title: 'Hooked: How to Build Habit-Forming Products',
        author: 'Nir Eyal',
        dateRead: 'June 2020',
      },
      {
        title: 'Surrounded by Idiots',
        author: 'Thomas Erikson',
        dateRead: 'June 2020',
      },
      {
        title: 'The Art of Songwriting',
        author: 'Ed Bell',
        dateRead: 'June 2020',
      },
      {
        title: 'Writing Better Lyrics',
        author: 'Pat Pattison',
        dateRead: 'May 2020',
      },
      {
        title: 'Popular Lyric Writing',
        author: 'Andrea Stolpe',
        dateRead: 'May 2020',
      },
      {
        title: 'Songwriting',
        author: 'Pat Pattison',
        dateRead: 'April 2020',
      },
      {
        title: 'Grit',
        author: 'Angela Duckworth',
        dateRead: 'April 2020',
      },
      {
        title: "James Acaster's Classic Scrapes",
        author: 'James Acaster',
        dateRead: 'April 2020',
      },
      {
        title: 'Master Your Emotions',
        author: 'Thibaut Meurisse',
        dateRead: 'April 2020',
      },
      {
        title: "The Complete Idiot's Guide to Music Composition",
        author: 'Michael Miller',
        dateRead: 'April 2020',
      },
      {
        title: 'Declutter Your Mind',
        author: 'S.J. Scott, Barrie Davenport',
        dateRead: 'April 2020',
      },
      {
        title: 'Deep Work',
        author: 'Cal Newport',
        dateRead: 'April 2020',
      },
      {
        title: 'Catching the Big Fish',
        author: 'David Lynch',
        dateRead: 'March 2020',
      },
      {
        title: 'Born a Crime: Stories from a South African Childhood',
        author: 'Trevor Noah',
        dateRead: 'February 2020',
      },
      {
        title: 'The Happiness Project',
        author: 'Gretchen Rubin',
        dateRead: 'February 2020',
        // audiobook: true,
      },
      {
        title: 'Make Time: How to Focus on What Matters Every Day',
        author: 'Jake Knapp',
        dateRead: 'January 2020',
      },
    ],
  },
  {
    year: '2019',
    books: [
      {
        title:
          'Elevate: Push Beyond Your Limits and Unlock Success in Yourself and Others',
        author: 'Robert Glazer',
        dateRead: 'December 2019',
      },
      {
        title: 'Dave Barry Slept Here: A Sort of History of the United States',
        author: 'Dave Barry',
        dateRead: 'December 2019',
      },
      {
        title: 'The Little Book of Coffee',
        author: 'Summersdale',
        dateRead: 'December 2019',
      },
      {
        title: "It's Not How Good You Are, It's How Good You Want to Be",
        author: 'Paul Arden',
        dateRead: 'December 2019',
      },
      {
        title: 'Stillness Is the Key',
        author: 'Ryan Holiday',
        dateRead: 'December 2019',
      },
      {
        title: "Fail Until You Don't",
        author: 'Bobby Bones',
        dateRead: 'November 2019',
      },
      {
        title:
          'The Obstacle Is the Way: The Timeless Art of Turning Trials into Triumph',
        author: 'Ryan Holiday',
        dateRead: 'November 2019',
      },
      {
        title: 'Emotional Intelligence',
        author: 'Gill Hasson',
        dateRead: 'November 2019',
      },
      {
        title: 'The Crossroads of Should and Must',
        author: 'Elle Luna',
        dateRead: 'November 2019',
      },
      {
        title: 'Attention Management',
        author: 'Maura Thomas',
        dateRead: 'October 2019',
      },
      {
        title: 'Feminist Fight Club',
        author: 'Jessica Bennett',
        dateRead: 'October 2019',
      },
      {
        title: 'So You Want to Talk About Race',
        author: 'Ijeoma Oluo',
        dateRead: 'October 2019',
      },
      {
        title: 'Stop Doing That Sh*t',
        author: 'John Gary Bishop',
        dateRead: 'September 2019',
      },
      {
        title: 'Keep Going',
        author: 'Austin Kleon',
        dateRead: 'September 2019',
      },
      {
        title: 'Harry Potter and the Prisoner of Azkaban',
        author: 'J.K. Rowling',
        dateRead: 'September 2019',
      },
      {
        title: 'Show Your Work!',
        author: 'Austin Kleon',
        dateRead: 'August 2019',
      },
      {
        title: 'Essentialism: The Disciplined Pursuit of Less',
        author: 'Greg McKeown',
        dateRead: 'August 2019',
      },
      {
        title: 'If Hemingway Wrote JavaScript',
        author: 'Angus Croll',
        dateRead: 'August 2019',
      },
      {
        title: 'I Am an Emotional Creature',
        author: 'Eve Ensler',
        dateRead: 'August 2019',
      },
      {
        title:
          'The Productivity Project: Accomplishing More by Managing Your Time, Attention, and Energy',
        author: 'Chris Bailey',
        dateRead: 'August 2019',
      },
      {
        title: 'Howl and Other Poems',
        author: 'Allen Ginsberg',
        dateRead: 'August 2019',
      },
      {
        title: 'Why We Sleep',
        author: 'Matthew Walker',
        dateRead: 'August 2019',
      },
      {
        title: 'Unf*ck Yourself: Get Out of Your Head and into Your Life',
        author: 'John Gary Bishop',
        dateRead: 'July 2019',
      },
      {
        title: 'The Subtle Art of Not Giving a F*ck',
        author: 'Mark Manson',
        dateRead: 'July 2019',
      },
      {
        title: 'Gut Feelings: The Intelligence of the Unconscious',
        author: 'Gerd Gigerenzer',
        dateRead: 'July 2019',
      },
      {
        title: 'Buddha: His Life and Teachings and Impact on Humanity',
        author: 'Osho',
        dateRead: 'June 2019',
      },
      {
        title: 'Ego Is the Enemy',
        author: 'Ryan Holiday',
        dateRead: 'June 2019',
      },
      {
        title: 'Harry Potter and the Chamber of Secrets',
        author: 'J.K. Rowling',
        dateRead: 'June 2019',
      },
      {
        title: 'Outliers: The Story of Success',
        author: 'Malcolm Gladwell',
        dateRead: 'June 2019',
      },
      {
        title: 'Mindset: The New Psychology of Success',
        author: 'Carol S. Dweck',
        dateRead: 'June 2019',
      },
      {
        title: 'Things Are What You Make of Them',
        author: 'Adam J. Kurtz',
        dateRead: 'June 2019',
      },
      {
        title: 'Dear Ijeawele, or a Feminist Manifesto in Fifteen Suggestions',
        author: 'Chimamanda Ngozi Adichie',
        dateRead: 'May 2019',
      },
      {
        title: "Harry Potter and the Sorcerer's Stone",
        author: 'J.K. Rowling',
        dateRead: 'May 2019',
      },
      {
        title: 'Steal Like an Artist',
        author: 'Austin Kleon',
        dateRead: 'May 2019',
      },
    ],
  },
];
